$('.owl-carousel').each(function(index, el){
	/*
		<div class="owl-carousel"
		 data-xs="1" 
		 data-sm="1" 
		 data-md="2" 
		 data-lg="2" 
		 data-xl="3" 
		 data-margin="30">...</div>
	*/

	var owlLists = (function(){
		function owlLists(el){
			this.xs = el.data('xs') || 1;
			this.sm = el.data('sm') || this.xs;
			this.md = el.data('md') || this.sm;
			this.lg = el.data('lg') || this.md;
			this.xl = el.data('xl') || this.lg;
		}

		return owlLists;
	})();

	var owlWrapList = new owlLists($(this));
	var items 		= $(this).children();
	var margin 		= $(this).data('margin') || 0;
	var autoplay 	= $(this).data('autoplay') || true;
	var nav 		=	$(this).data('nav') || false;
	var loop 		= $(this).data('loop') || false;

	var options = {
		autoplay: autoplay,
		nav: nav,
		responsive : {
			0 : {
				items 	: owlWrapList.xs,
				loop	: loop ? owlWrapList.xs <= items.length : false,
				margin  : owlWrapList.xs > 1 ? margin : 0
			},
			576:{
				items	: owlWrapList.sm,
				loop	: loop ? owlWrapList.sm <= items.length : false,
				margin  : owlWrapList.sm > 1 ? margin : 0
			},
			768 : {
				items	: owlWrapList.md,
				loop	: loop ? owlWrapList.md <= items.length : false,
				margin  : owlWrapList.md > 1 ? margin : 0
			},
			992 :{
				items	: owlWrapList.lg,
				loop	: loop ? owlWrapList.lg <= items.length : false,
				margin  : owlWrapList.lg > 1 ? margin : 0
			},
			1200 : {
				items	: owlWrapList.xl,
				loop	: loop ? owlWrapList.xl <= items.length : false,
				margin  : owlWrapList.xl > 1 ? margin : 0
			}
		}
	};

	$(this).owlCarousel(options);

});