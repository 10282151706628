$('[data-toggle="menu"]').click(function(){
	var alvo = $(this).data('target')

	$(alvo).toggleClass('active');
});

$('[data-toggle="active"]').click(function(){
	var alvo = $(this).data('target')
	$(alvo).toggleClass('active');
});

$('[data-toggle="popup"]').click(function(){
	var alvo = $(this).data('target')
	$(alvo).toggleClass('active');
});
